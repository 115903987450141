import React from "react"
import {Link} from "gatsby"
import SEO from "../components/seo"

const AccessDenied = () => (
  <section style={{backgroundColor: '#f6f6f6'}}>
    <SEO title="403: Access Denied" />
    <div className="inner inner-account inner-account-header">
      <h1>We're Sorry!</h1>
      <p>It looks like you don't have access to this page. Please log in or <Link to="/signup/individuals">Sign up</Link>.</p>
    </div>
  </section>
)

export default AccessDenied
